import { ObjectId } from 'bson';
import { ValueOf } from './utils/helper';

export enum Tables {
  Orders = 'Orders',
  ServiceOrders = 'ServiceOrders',
  Users = 'Users',
  Clients = 'Clients',
  Contractors = 'Contractors',
  Sequences = 'Sequences',
  InspectionModels = 'InspectionModels',
  InspectionData = 'InspectionData',
  Comments = 'Comments',
  Tags = 'Tags',
  SmsHistories = 'SmsHistories',
  KpiHistories = 'KpiHistories',
  BonusHistories = 'BonusHistories',
  FinancialYears = 'FinancialYears',
  Properties = 'Properties',
  ExternalAccounts = 'ExternalAccounts',
  NotificationSchedules = 'NotificationSchedules',
  SnapInspections = 'SnapInspections',
  AddressMatchRules = 'AddressMatchRules'
}

export interface Notification {
  time: Date;
  notified?: boolean;
  notifiedAt?: Date;
}

enum NotificationMethod {
  SMS = 'SMS',
  Email = 'Email'
}

type SyncJobKeys = 'SA' | 'HHA' | 'MTLD';

export interface SyncSettings {
  repeatIntervalDays?: number;
  repeatPrice?: number;
  normalPrice?: number;
  packagePrice?: number;
  syncEnabled?: boolean;
}
export interface ExternalAccount {
  _id: ObjectId;
  username: string;
  password: string;
  type: 'Palace';
  linkedEmail: string;
  mobile?: string;
  lastSyncedAt?: Date;
  contactName?: string;
  company?: string;
  createdAt: Date;
  updatedAt?: Date;
  onlyNotifyAccountManager?: boolean;
  agentEmailsToNotify?: string[];
  syncFromSnapInspect?: boolean;
  syncSettings?: Record<SyncJobKeys, SyncSettings>;
}
export interface NotificationSchedule {
  _id: ObjectId;
  notifyId: string;
  retrieveId?: string;
  type: 'SA' | 'HHA';
  fullAddress: string;
  contactName: string;
  email?: string;
  mobile?: string;
  lastCheckedTime: Date;
  notifies: Notification[];
  methods: NotificationMethod[];
  isAgent: boolean;
  externalAccountId?: ObjectId;
  isAnnualRepeat: boolean;
  active: boolean;
  propertyId?: string;
  lastOrderId?: ObjectId;
  createdAt: Date;
  newOrderId?: ObjectId;
  clientId?: ObjectId;
  hasCancelled?: boolean;
  skipExpireDate?: boolean;
}

export interface FinancialYear {
  _id: ObjectId;
  year: string;
  month: number;
}

export enum UserRoles {
  Admin = 'Admin',
  Inspector = 'Inspector',
  Auditor = 'Auditor',
  SuperAdmin = 'SuperAdmin'
}

export enum OrderStatus {
  ContactPending = 'ContactPending',
  ContactSupplied = 'ContactSupplied',
  AgreementPending = 'AgreementPending',
  AgreementSupplied = 'AgreementSupplied',
  Ready = 'Ready',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Cancelled = 'Cancelled'
}

export enum ServiceOrderStatus {
  ContactPending = 'ContactPending',
  ContactSupplied = 'ContactSupplied',
  AgreementPending = 'AgreementPending',
  AgreementSupplied = 'AgreementSupplied',
  Quoted = 'Quoted',
  PrePaymentPending = 'PrePaymentPending',
  Ready = 'Ready',
  InProgress = 'InProgress',
  Cancelled = 'Cancelled',
  FinalPaymentPending = 'FinalPaymentPending',
  Completed = 'Completed'
}

export enum JobStatus {
  Draft = 'Draft',
  Ready = 'Ready',
  Assigned = 'Assigned',
  InRevisal = 'InRevisal',
  Completed = 'Completed',
  Audited = 'Audited',
  ReportSent = 'ReportSent'
}

export enum ServiceJobStatus {
  Draft = 'Draft',
  Accepted = 'Accepted',
  Cancelled = 'Cancelled',
  Ready = 'Ready',
  Completed = 'Completed'
}

export enum SequenceType {
  Order = 'Order',
  Service = 'Service',
  TestOrder = 'TestOrder'
}

export enum OrderTypes {
  Inspection = 'Inspection',
  Service = 'Service'
}

export enum CommentsCategories {
  SmokeAlarm = 'SmokeAlarm',
  MethReport = 'Meth',
  BuildingInspection = 'BuildingInspection'
}

export type BuildingInspection = {
  type: 'BuildingInspectionVerbal' | 'BuildingInspectionWritten';
  isApartment?: boolean;
  category?: 'PrePurchase' | 'PreSale' | 'WeatherTightness' | 'PreDelivery' | 'Maintenance' | 'ApartmentInterior';
  shortKey: 'BIW' | 'BIV';
} & BaseJob;

export type MethTesting = {
  shortKey: 'MTID' | 'MTLD' | 'MTIFC' | 'MTLFC' | 'MTCV' | 'MTCW' | 'CMW' | 'CMV';
  type:
    | 'MethTestIndividualDiscrete'
    | 'MethTestLabDiscrete'
    | 'MethTestIndividualFieldComposite'
    | 'MethTestLabFieldComposite'
    | 'Check4MethWritten'
    | 'Check4MethVerbal';
  docTypes: ['LabReport'];
} & BaseJob;

export type Check4MethVerbal = {
  shortKey: 'MTC4MV';
  type: 'Check4MethVerbal';
  docTypes: [];
} & BaseJob;

type SafeSanitary = {
  shortKey: 'SS';
  type: 'SafeSanitaryCheck';
  docTypes: ['Report'];
} & BaseJob;

type HealthyHomesAssessment = {
  shortKey: 'HHA';
  type: 'HealthyHomesAssessment';
  docTypes: ['ComplianceReport', 'HeatingReport'];
} & BaseJob;

type SmokeAlarmAssessment = {
  shortKey: 'SA';
  type: 'SmokeAlarmAssessment';
  docTypes: ['Report'];
} & BaseJob;

type InsulationAssessment = {
  shortKey: 'IA';
  type: 'InsulationAssessment';
  docTypes: ['Report'];
} & BaseJob;

type HeatingAssessment = {
  shortKey: 'HA';
  type: 'HeatingAssessment';
  docTypes: ['Report'];
} & BaseJob;

type AsbestosTesting = {
  shortKey: 'AT';
  type: 'AsbestosTesting';
  docTypes: ['Report'];
} & BaseJob;

type SwimmingPoolSafetyInspection = {
  shortKey: 'SP';
  type: 'SwimmingPoolSafetyInspection';
  docTypes: ['Report'];
} & BaseJob;

type ConditionSurveyReport = {
  shortKey: 'CS';
  type: 'ConditionSurveyReport';
  docTypes: ['Report'];
} & BaseJob;

type IngoingConditionReport = {
  shortKey: 'IC';
  type: 'IngoingConditionReport';
  docTypes: ['Report'];
} & BaseJob;

export type ConsultancyService = {
  shortKey: 'CSW' | 'CSV';
  type: 'ConsultancyServiceVerbal' | 'ConsultancyServiceWritten';
  docTypes: ['Report'];
} & BaseJob;

export type PostInspectionService = {
  shortKey: 'PISW' | 'PISV';
  type: 'PostInspectionServiceVerbal' | 'PostInspectionServiceWritten';
  docTypes: ['Report'];
} & BaseJob;

export const AllJobTypes = [
  'BuildingInspectionVerbal',
  'BuildingInspectionWritten',
  'MethTestIndividualDiscrete',
  'MethTestLabDiscrete',
  'MethTestIndividualFieldComposite',
  'MethTestLabFieldComposite',
  'Check4MethWritten',
  'Check4MethVerbal',
  'SafeSanitaryCheck',
  'HealthyHomesAssessment',
  'SmokeAlarmAssessment',
  'InsulationAssessment',
  'HeatingAssessment',
  'AsbestosTesting',
  'SwimmingPoolSafetyInspection',
  'ConditionSurveyReport',
  'IngoingConditionReport',
  'ConsultancyService',
  'ConsultancyServiceVerbal',
  'PostInspectionServiceVerbal',
  'PostInspectionServiceWritten'
];

export type Job =
  | BuildingInspection
  | MethTesting
  | SafeSanitary
  | HealthyHomesAssessment
  | SmokeAlarmAssessment
  | InsulationAssessment
  | HeatingAssessment
  | AsbestosTesting
  | SwimmingPoolSafetyInspection
  | ConditionSurveyReport
  | IngoingConditionReport
  | ConsultancyService
  | PostInspectionService;

export interface Contractor {
  _id: ObjectId;
  company: string;
  name: string;
  mobile: string;
  email: string;
  bankAccount: string;
}

export interface StaffRatio {
  userId: string;
  email: string;
  mobile?: string;
  userName: string;
  ratio: number;
}

export interface ContractPayment {
  invoiceNumber?: string;
  paidAt?: Date;
  operator?: string;
}
export interface ServiceJob {
  jobId: string;
  title: string;
  description?: string;
  status: ServiceJobStatus;
  schedule?: Date;
  photos?: S3Object[];
  docs?: Record<string, Doc>;
  contractor: Contractor & ContractPayment;
  priceAdjustments?: {
    changeInCents: number;
    at: Date;
    userName: string;
    target: 'AdditionalCost' | 'ContractorQuote' | 'WorkPrice';
  }[];
  contractorQuoteInCents: number;
  additionalCostInCents?: number;
  costDescription?: string;
  totalCostInCents: number;
  workPriceInCents: number;
  staffRatios: StaffRatio[];
  contractorRemark?: string;
  workFiles: S3Object[];
  hasSentWorkOrder?: boolean;
  prepaidAmountInCents?: number;
  finalPaymentInCents?: number;
  docsSent?: boolean;
}

export interface Address {
  memo?: string;
  fullAddress: string;
  city?: string;
  suburb?: string;
  postcode?: string;
  location?: [number, number];
}

export interface TenantDetail {
  name: string;
  email: string;
  mobile: string;
  doorCode?: string;
  gateCode?: string;
  alarmCode?: string;
  keyLocation?: string;
  remark?: string;
}

export interface S3Object {
  template?: string;
  bucket: string;
  key: string;
}

export interface User {
  _id: ObjectId;
  firstName: string;
  lastName: string;
  password: string;
  email: string;
  securityEmail?: string;
  mobile?: string;
  role: UserRoles;
  salary?: number;
  bonusRatio?: number;
  signatureId?: string;
  sessionId?: string;
  disabled?: boolean;
  updatedAt?: Date;
  createdAt?: Date;
}

export interface Client {
  _id: ObjectId;
  vip?: boolean;
  firstName: string;
  middleName?: string;
  lastName?: string;
  company?: string;
  email?: string;
  mobile: string;
  type: 'Agent' | 'Customer';
  addresses?: Address[];
}

export interface InspectionOrder extends BaseOrder {
  type: OrderTypes.Inspection;
  jobs: Job[];
  status: OrderStatus;
  invoiceTo?: string;
  invoiceEmail?: string;
  ccEmails?: string[];
  tenants?: TenantDetail[];
  parentOrder?: ObjectId;
  childOrders?: ObjectId[];
  paid?: boolean;
  paidAt?: Date;
  invoiceNumber?: string;
  totalQuote?: number;
  reportQuotes?: {
    title: string;
    price: number;
  }[];
  paymentReceivedAt?: Date;
  referralRate?: number;
  referralBank?: string;
  updatedAt?: Date;
  auditCompleted?: boolean;
  invoiceIssuedAt?: Date;
  archive?: string;
  externalAccountId?: ObjectId;
  externalPropertyId?: string;
  previousOrderId?: ObjectId;
  syncedToExternalSource?: boolean;
}

export interface Referral {
  fullName: string;
  company: string;
  mobile: string;
  email: string;
  bankAccount: string;
}

export interface BaseOrder {
  _id: ObjectId;
  archive?: string;
  docSignId?: string;
  orderNumber: string;
  invoiceNo?: string;
  clientId: ObjectId;
  client: Pick<Client, 'firstName' | 'lastName' | 'middleName' | 'company' | 'email' | 'mobile' | 'type'>;
  createdAt: Date;
  preferredDate?: Date;
  address?: Address;
  referral?: Referral;
  invoiceTo?: string;
  invoiceEmail?: string;
  ccEmails?: string[];
  skipContactForm?: boolean;
  skipAgreement?: boolean;
  sentReport?: boolean;
  signingDocuments?: {
    [key: string]: S3Object | null;
  };
  lastUpdatedAt?: Date;
  kpiCalculated: boolean;
  isAnnualRepeat?: boolean;
  company?: string;
  clientReference?: string;
}

export interface ServiceOrder extends BaseOrder {
  type: OrderTypes.Service;
  prepayRatio: number;
  status: ServiceOrderStatus;
  docSignId?: string;
  jobs?: ServiceJob[];
  address?: Address;
  tenants?: TenantDetail[];
  parentOrder?: ObjectId | undefined;
  childOrders?: ObjectId[];
  clientRemark?: string;
  prepayment?: {
    invoiceNumber?: string;
    issuedAt?: Date;
    paidAt?: Date;
    paid: boolean;
    amountInCent?: number;
  };
  finalPayment?: {
    invoiceNumber?: string;
    issuedAt?: Date;
    paidAt?: Date;
    amountInCent: number;
    paid: boolean;
  };
}

export interface Sequence {
  _id: ObjectId;
  type: string;
  year: number;
  lastSeq: number;
}

export interface Doc {
  file?: S3Object;
  type: 'PDF';
  createdAt: Date;
  category?: string;
  status: 'Pending' | 'Available';
}

export interface BaseJob {
  jobId: string;
  shortKey: string;
  auditor?: User['_id'];
  auditedAt?: Date;
  inspector?: User['_id'];
  schedule?: Date;
  quote?: number;
  docs?: Record<string, Doc>;
  labResults?: Record<string, Doc>;
  docTypes?: string[];
  status: JobStatus;
  description?: string;
  ref?: string;
  durationHours?: number;
  docsSent?: boolean;
  category?: keyof typeof BuildingCategories;
  staffRatios?: StaffRatio[];
  costInCents?: number;
  costDescription?: string;
  marginInCents?: number;
  annualNotifyDisabled?: boolean;
}

export type JobTypes = Pick<Job, 'type'>;

export interface InspectionModel {
  type: JobTypes;
  model: Object;
  updatedAt?: Date;
  createdAt: Date;
}

export interface Comment {
  _id: ObjectId;
  title: string;
  content: string;
  category: CommentsCategories;
  tags: string[];
}

export interface Tag {
  _id: ObjectId;
  category: string;
  tag: string;
}

export interface InspectionData {
  _id: ObjectId;
  data: Record<string, any>;
  jobType: ValueOf<JobTypes>;
  jobId: string;
  pages: PageConfig[];
  inspector: User['_id'];
  createdAt: Date;
  updatedAt: Date;
}

export interface InspectionModel {
  _id: ObjectId;
  type: JobTypes;
  model: Object;
  createdAt: Date;
  updatedAt?: Date;
}

export interface PageConfig {
  id: string;
  isHidden: boolean;
  index: number;
}

export interface Event {
  id: string;
  title: string;
  allDay?: string;
  start?: string;
  end?: string;
}

export interface SmsHistory {
  _id: ObjectId;
  to: string;
  content: string;
  result: any;
}

export interface JobKpiHistory {
  jobId: string;
  ratio: number;
  isComplete: boolean;
  totalCost: number;
  workPrice: number;
  kpi: number;
}

export interface KPIHistory {
  _id: ObjectId;
  month: number;
  userId: string;
  email: string;
  staffName: string;
  totalKpi: number;
  totalIncompleteKpi: number;
  jobs: JobKpiHistory[];
  createdAt: Date;
}

export enum BuildingCategories {
  PrePurchase = 'Pre-Purchase',
  PreSale = 'Pre-Sale',
  WeatherTightness = 'Weather Tightness',
  PreDelivery = 'Pre-Delivery',
  Maintenance = 'Maintenance',
  ApartmentInterior = 'Apartment Interior'
}

export interface BonusHistory {
  _id: ObjectId;
  userId: string;
  staffName: string;
  endMonth: number;
  bonusRatio: number;
  salary: number;
  totalCompletedKpi: number;
  bonus: number;
  updatedAt: Date;
}

export interface AddressMatchRule {
  _id: ObjectId;
  externalAccountId: ObjectId;
  externalPropertyId: string;
  externalAccountType: 'Palace';
  externalAddress: string;
  orderAddress?: string;
  snapAddress?: string;
  snapInspectionId?: SnapInspection['inspectionId'];
  orderNumber?: string;
  snapAccountId?: SnapInspection['accountId'];
  createdAt: Date;
  // Left it to 'Manual' when manualResolution is 'New'
  // Pending: wait for auto address matching
  // Matched: matched with an order and/or snap inspection
  // Manual: require manual resolution
  // Ignore: ignore this address
  matchingStatus: 'Matched' | 'Manual' | 'Ignore' | 'Pending';
  // needs update matchingStatus to 'Order' or 'Snap' when manualResolution is 'Order' or 'Snap'
  matchingResolution?: 'Order' | 'Snap' | 'Order-Snap' | 'New' | 'Ignore';
  updatedAt?: Date;
}

export interface SnapInspection {
  _id: ObjectId;
  inspectionId: number;
  accountId: number;
  orderNumber?: string;
  fullAddress: string;
  geoLocation?: [number, number];
  startDate?: Date;
  endDate?: Date;
  createdAt: Date;
  updatedAt?: Date;
  jobNumber?: string;
  docs: {
    [key: string]: {
      category: 'HHA Report' | 'Heating Report';
      createdAt: Date;
      file: S3Object;
    };
  };
  heating?: {
    source: string;
    compliantStatus: string;
    totalHeatCapacity: string;
    requiredHeatCapacity: string;
    comments?: string;
    fireplace?: string;
  };
  ceilInsulation?: {
    compliantStatus: string;
    insulationType: string;
    thickness: string;
    comments?: string;
    insulationDate?: string;
  };
  floorInsulation?: {
    compliantStatus: string;
    insulationType: string;
    thickness?: string;
    comments?: string;
    insulationDate?: string;
  };
  kitchens?: {
    exhaustFan: string;
    comments?: string;
    gapCompliantStatus: string;
  }[];
  bathrooms?: {
    compliantStatus: string;
    exhaustFan: string;
    comments?: string;
    gapCompliantStatus: string;
  }[];
  toilets?: {
    compliantStatus: string;
    ventilationType: string;
    comments?: string;
    gapCompliantStatus: string;
  }[];
  laundries?: {
    compliantStatus: string;
    ventilationType: string;
    comments?: string;
    gapCompliantStatus: string;
  }[];
  bedrooms?: {
    compliantStatus: string;
    ventilationType: string;
    comments?: string;
    gapCompliantStatus: string;
  }[];
  livingRooms?: {
    compliantStatus: string;
    ventilationType: string;
    comments?: string;
    gapCompliantStatus: string;
  }[];
  dinningRooms?: {
    compliantStatus: string;
    ventilationType: string;
    comments?: string;
    gapCompliantStatus: string;
  }[];
  entries?: {
    compliantStatus: string;
    ventilationType: string;
    comments?: string;
    gapCompliantStatus: string;
  }[];
  hallways?: {
    compliantStatus: string;
    ventilationType: string;
    comments?: string;
    gapCompliantStatus: string;
  }[];
  conservatories?: {
    compliantStatus: string;
    ventilationType: string;
    comments?: string;
    gapCompliantStatus: string;
  }[];
  drainageAndGuttering?: {
    compliantStatus: string;
    comments?: string;
  };
  groundMoistureAndDrainage?: {
    compliantStatus: string;
    foundationType: string;
    comments?: string;
  };
}
